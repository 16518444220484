import curatedPortfolio from '../../../../../assets/img/home/curatedPortfolio.svg';
import diversity from '../../../../../assets/img/home/diversity.svg';
import performance from '../../../../../assets/img/home/performance.svg';

import investmentWineSvg from '../../../../../assets/img/home/investmentWine.svg';
import growth from '../../../../../assets/img/home/growth.svg';
import hassleFree from '../../../../../assets/img/home/hassle-free.svg';

import styles from './RewardsSection.module.scss';
import React from 'react';
import { useScrollReveal } from '~/hooks/useScrollReveal';
import Image from 'next/image';
import { HomeButton } from '../HomeButton';
import { useWindowSize } from '~/hooks/useWindowSize';

const valueProps = [
    {
        header: 'Wealth Protection',
        description:
            "Fine wine and whiskey's low correlation with the stock market shields your wealth from the sting of inflation and recessions.",
        svg: diversity,
        imgClassName: 'img2',
        width: '97',
        height: '125'
    },
    {
        header: '100% Ownership',
        description:
            'You only own stocks and bonds on paper. At Vinovest, you own your wine and whiskey 100%. We will even ship your bottles to your doorstep if you want to drink them.',
        svg: investmentWineSvg,
        imgClassName: 'img5',
        width: '62',
        height: '113'
    },
    {
        header: 'Proven Performance',
        description:
            'Fine wine has delivered 10.6% annualized returns for more than two decades, outperforming global equities. Meanwhile, Knight Frank called whiskey "the best-performing collectable of the decade."',
        svg: performance,
        imgClassName: 'img3',
        width: '171',
        height: '132'
    },
    {
        header: 'Secure Storage',
        description:
            "A lot of work goes into properly owning wine and whiskey. But don't fret. We take care of the heavy lifting for you, including comprehensive storage, insurance, and authentication at our bonded warehouses.",
        svg: hassleFree,
        imgClassName: 'img4',
        width: '171',
        height: '132'
    },
    {
        header: 'Stable Growth',
        description:
            'Grow your wealth without all the dramatic ups and downs of the stock market. Goodbye, daily price swings. Hello, peace of mind.',
        svg: growth,
        imgClassName: 'img6',
        width: '171',
        height: '132'
    },
    {
        header: 'Curated Portfolios',
        description:
            'No two portfolios are the same. We listen to your goals before selecting wine and whiskey that matches your exact needs.',
        svg: curatedPortfolio,
        imgClassName: 'img1',
        width: '171',
        height: '132'
    }
];
export const RewardsSection = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { hasRevealed } = useScrollReveal({ ref });
    const runAnimation = hasRevealed ? styles.runAnimation : '';
    const { isMobile } = useWindowSize();

    return (
        <div className={styles.QualityContainerNew} ref={ref}>
            <div className={styles.Container}>
                <h2 className={`${styles.TopTitle} ${runAnimation}`}>Real assets. Real rewards.</h2>
                <div className={`${styles.CustomModuleContainer}`}>
                    {valueProps.map((p) => (
                        <div key={p.header} className={`${styles.PropContainer} ${runAnimation}`}>
                            <Image
                                className={`prop-img ${p.imgClassName}`}
                                src={p.svg}
                                alt={`${p.header} Graphic`}
                                loading={'lazy'}
                            />
                            <span className={`prop-header`}>{p.header}</span>
                            <span className={`prop-desc`}>{p.description}</span>
                        </div>
                    ))}
                </div>
            </div>
            {!isMobile && <HomeButton btnLocation={'benefits'} />}
        </div>
    );
};
