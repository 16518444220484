import React from 'react';
import cindy from '../../../../../assets/img/home/cindy.jpg';
import nicholas from '../../../../../assets/img/home/nicholas.jpg';
import romeo from '../../../../../assets/img/home/romeo.jpg';
import brad from '../../../../../assets/img/home/brad.jpg';
import cj from '../../../../../assets/img/home/cj.jpg';
import cindyColor from '../../../../../assets/img/home/cindy.png';
import nicholasColor from '../../../../../assets/img/home/nicholas.png';
import romeoColor from '../../../../../assets/img/home/romeo.png';
import bradColor from '../../../../../assets/img/home/brad.png';
import cjColor from '../../../../../assets/img/home/cj.png';
import Slider from 'react-slick';
import StarsRating from '../../../../../assets/img/home/stars-rating.svg';
import Image from 'next/image';
import styles from './Testimonials.module.scss';
import { useScrollReveal } from '~/hooks/useScrollReveal';
import { HomeButton } from '../HomeButton';
import { useWindowSize } from '~/hooks/useWindowSize';

export const testimonialsSliderData = [
    {
        to: null,
        id: 0,
        src: cindy,
        srcColor: cindyColor,
        title: 'Customer',
        description:
            "So far I'm very happy with the purchases that have been made for me. The staff has also been great in communicating.",
        bottomText: 'cindy, az, usa',
        since: '2021'
    },
    {
        to: null,
        id: 1,
        src: nicholas,
        srcColor: nicholasColor,
        title: 'Customer',
        description:
            'Amazed at the user friendly platform. It seems like the algorithms work really well to swoop in on good wine deals, seemingly to yield a profit instantly. Great customer service too!',
        bottomText: 'Nicholas, ca, usa',
        since: '2021'
    },
    {
        to: null,
        id: 4,
        src: cj,
        srcColor: cjColor,
        title: 'Customer',
        description:
            "I'm grateful to be a champion for Vinovest's work around making wine investing inclusive and equitable.",
        bottomText: 'CJ Mccollum, New Orleans Pelicans guard and owner of McCollum Heritage 91',
        since: '2022'
    },
    {
        to: null,
        id: 3,
        src: brad,
        srcColor: bradColor,
        title: 'Customer',
        description:
            'A call with my portfolio manager, Michael, was a great introduction to how everything works, what to expect, and how wine fits in my overall portfolio.',
        bottomText: 'brad, pa, usa',
        since: '2021'
    },
    {
        to: null,
        id: 2,
        src: romeo,
        srcColor: romeoColor,
        title: 'Customer',
        description:
            'Amazing service and quick response. Vinovest takes great care of customer requirements and does their utmost best to ensure great service.',
        bottomText: 'Romeo, Sydney, Australia',
        since: '2021'
    }
];

export const TedstimonialsSection = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { hasRevealed } = useScrollReveal({ ref });
    const runAnimation = hasRevealed ? styles.runAnimation : '';
    const { isMobile } = useWindowSize();

    return (
        <div className={`${styles.Wrapper}`} ref={ref}>
            <div className={`${styles.TitleWrapper}`}>
                <h2 className={`${styles.TopTitle} ${runAnimation}`}>See what our clients are saying</h2>
            </div>
            <div className={`${styles.SlickWrapper} ${runAnimation} ${styles.row3}`}>
                <div className={`${styles.full}`}>
                    <Slider
                        className={`${styles.TestimonialsSliderContainer}`}
                        accessibility
                        autoplay={false}
                        slidesToShow={3}
                        slidesToScroll={3}
                        arrows={false}
                        dots
                        responsive={[
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 3,
                                    infinite: true,
                                    dots: true
                                }
                            },
                            {
                                breakpoint: 932,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    infinite: true,
                                    centerMode: true,
                                    dots: false
                                }
                            },
                            {
                                breakpoint: 429,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    infinite: true,
                                    centerMode: true,
                                    dots: false
                                }
                            }
                        ]}
                    >
                        {testimonialsSliderData.map(({ id, srcColor, title, description, bottomText, since }) => (
                            <div key={id} className={`${styles.SlideWrapper}`}>
                                <div className={`${styles.SlideContainer} ${styles.clean}`}>
                                    <div className={'message'}>
                                        <Image
                                            className={'starsRating'}
                                            src={StarsRating}
                                            alt={'5 Stars'}
                                            height={'22'}
                                            width={'130'}
                                            loading={'lazy'}
                                        />
                                        <div className={'description'}>"{description}"</div>
                                    </div>
                                    <div className={'profile'}>
                                        <div className={'image'}>
                                            <Image
                                                className={'img'}
                                                src={srcColor}
                                                alt={title}
                                                width={'63'}
                                                loading={'lazy'}
                                            />
                                        </div>
                                        <div className={'profileDetails'}>
                                            <div className={'bottomText'}>{bottomText}</div>
                                            <div className={'sinceText'}>Client Since {since}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                    {!isMobile && <HomeButton btnLocation={'testimonials'} />}
                </div>
            </div>
        </div>
    );
};
