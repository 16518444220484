import Slider from 'react-slick';
import articleInWine from '../../../../../assets/img/home/articleInWine.jpg';
import articleInStocks from '../../../../../assets/img/home/articleInStocks.jpg';
import articleIncomplete from '../../../../../assets/img/home/articleIncomplete.jpg';
import readerSvg from '../../../../../assets/img/home/reader.svg';
import phoneSvg from '../../../../../assets/img/home/phone.svg';
import guideWhiskey from '../../../../../assets/img/home/guide-whiskey.jpg';
import smartInvestors from '../../../../../assets/img/home/smart-investors.jpg';
import champagne from '../../../../../assets/img/home/champagne.jpg';
import { Article } from './Article';
import React from 'react';
import { useScrollReveal } from '~/hooks/useScrollReveal';
import styles from './NewsSection.module.scss';
import Image from 'next/image';
import { baseRoutes } from '~/routes/base';
import { useWindowSize } from '~/hooks/useWindowSize';

export const latestFromBlogData = [
    {
        to: '/blog/investing-in-wine',
        id: 0,
        src: articleInWine,
        title: 'Investing In Wine: Everything You Need To Know',
        topImg: phoneSvg
    },
    {
        to: '/blog/stock-investing-wine-investing',
        id: 1,
        src: articleInStocks,
        title: 'Investing In Stocks Vs. Investing In Wine',
        topImg: null
    },
    {
        to: '/blog/why-your-investment-portfolio-is-incomplete-without-fine-wine',
        id: 2,
        src: articleIncomplete,
        title: 'Why Your Investment Portfolio Is Incomplete Without Fine Wine',
        topImg: readerSvg
    },
    {
        to: '/blog/whiskey-investment/',
        id: 3,
        src: guideWhiskey,
        title: 'Guide To Whiskey Investment: 10 Best Bottles, Expert Tips',
        topImg: null
    },
    {
        to: '/blog/wine-during-recessions',
        id: 4,
        src: smartInvestors,
        title: 'Why smart investors put their money in wine during bear markets',
        topImg: null
    },
    {
        to: '/blog/investing-in-champagne',
        id: 5,
        src: champagne,
        title: 'Investing in Champagne: A Lucrative Collectible for Your Portfolio',
        topImg: null
    }
];

export const NewsSection = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { hasRevealed } = useScrollReveal({ ref });
    const runAnimation = hasRevealed ? styles.runAnimation : '';
    const { isMobile } = useWindowSize();

    return (
        <div className={`${styles.Wrapper}`} ref={ref}>
            <div>
                <div className={`${styles.TitleWrapper}`}>
                    <h2 className={`${styles.TopTitle} ${runAnimation}`}>The latest news and resources</h2>
                    <p className={`${styles.BottomDescription} ${runAnimation}`}>
                        Become your smartest self with our entire library of helpful tips, how-tos, research, and
                        reports.
                    </p>
                    <a href={baseRoutes.blog.href} className={`${styles.ReadMoreLink} ${runAnimation}`}>
                        Read More
                    </a>
                </div>
                {!isMobile && (
                    <div className={`${styles.BlogListContainer} ${runAnimation}`}>
                        <div className={`${styles.BlogListWrapper}`}>
                            {latestFromBlogData.map(({ id, ...props }) => (
                                <Article key={id} {...props} />
                            ))}
                        </div>
                    </div>
                )}
                {isMobile && (
                    <div>
                        <Image className={`${styles.reader}`} src={readerSvg} alt={'reader'} loading={'lazy'} />
                        <Slider
                            className={'blog-slider-container'}
                            accessibility
                            infinite
                            autoplay={false}
                            slidesToShow={1}
                            slidesToScroll={1}
                            arrows={false}
                            dots
                        >
                            {latestFromBlogData.map(({ id, ...props }) => (
                                <div key={id}>
                                    <div>
                                        <Article {...props} />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                )}
            </div>
        </div>
    );
};
