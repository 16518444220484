import { Hero } from './components/HeroSection/HeroSection';
import { InvestSection } from './components/InvestSection/InvestSection';
import { NewsSection } from './components/NewsSection/NewsSection';
import { OfferingsSection } from './components/OfferingsSection/OfferingsSection';
import { ReturnsSection } from './components/ReturnsSection/ReturnsSection';
import { RewardsSection } from './components/RewardsSection/RewardsSection';
import { TedstimonialsSection } from './components/TestimonialsSection/TestimonialsSection';

export const HomePage = () => {
    return (
        <>
            <Hero />
            <TedstimonialsSection />
            <ReturnsSection />
            <RewardsSection />
            <InvestSection />
            <OfferingsSection />
            <NewsSection />
        </>
    );
};
