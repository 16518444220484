import { type RefObject, useEffect, useState } from 'react';

export const useScrollReveal = ({ ref }: { ref: RefObject<HTMLDivElement> }) => {
    const [hasRevealed, setHasRevealed] = useState(false);

    useEffect(() => {
        if (!ref.current) return undefined;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setHasRevealed(true);
                    observer.disconnect();
                }
            },
            { threshold: 0 }
        );
        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [hasRevealed, ref]);

    return { hasRevealed };
};
