import Image, { StaticImageData } from 'next/image';
import styled from 'styled-components';
import Link from 'next/link';

export interface ArticleProps {
    title: string;
    to: string;
    src: StaticImageData;
    topImg?: string;
}

export const Article = ({ title, to, src, topImg }: ArticleProps) => (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <Wrapper href={to}>
        {topImg && <Image className={'topImg'} src={topImg} alt={title} />}
        <Image className={`blocImg`} src={src} alt={title} width={322} height={215} loading={'lazy'} />
        <h3 className={`blogHeading font-roslindale`}>{title}</h3>
    </Wrapper>
);

const Wrapper = styled(Link)`
    background-color: #fff4e8;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: #242e35;
    outline: 0;
    text-decoration: none;

    .blogHeading {
        font-size: 24px;
        font-weight: 500;
        line-height: 150%;
        margin: 20px auto;
        max-width: 325px;
    }

    &:hover {
        background-color: #efddc7;
        cursor: pointer;
    }

    .topImg {
        position: absolute;
        width: 100%;
        max-width: 334px;
        height: 236px;
        object-fit: contain;
        top: 0;
        transform: translateY(-100%);
    }

    .blocImg {
        width: 100%;
        display: block;
        object-fit: contain;
    }

    p {
        flex-grow: 1;
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 160%;
        font-family: Favoritstd, sans-serif;
        font-weight: 500;
    }

    .authorWrapper {
        display: flex;
        margin-top: auto;
        align-items: center;

        .img_author {
            height: auto;
            width: 48px;
            margin-right: 24px;
            flex: 0 0 auto;
            display: block;
            object-fit: contain;
        }

        h4 {
            margin-top: 10px;
            margin-bottom: 10px;
            font-family: Favoritstd, sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            font-weight: 500;
            letter-spacing: 0.035em;
        }
    }
`;
