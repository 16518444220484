import posthog from 'posthog-js';
import { Button } from '~/vinovest-design-system/components';
import wine from '../../../../../assets/img/home/wine-offering.png';
import wineMobile from '../../../../../assets/img/home/wine-offering-mo.png';
import whiskey from '../../../../../assets/img/home/whiskey-offering.png';
import Image from 'next/image';
import { baseRoutes } from '~/routes/base';
import styles from './OfferingsSection.module.scss';
import React from 'react';
import { useScrollReveal } from '~/hooks/useScrollReveal';
import { useWindowSize } from '~/hooks/useWindowSize';

export const OfferingsSection = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { hasRevealed } = useScrollReveal({ ref });
    const runAnimation = hasRevealed ? styles.runAnimation : '';
    const { isMobile } = useWindowSize();
    const wineNext = () => {
        posthog.capture('select_invest_in', {
            type: 'wine',
            location: 'offerings'
        });
        window.location.assign('/signup');
    };

    const whiskeyNext = () => {
        posthog.capture('select_invest_in', {
            type: 'whiskey',
            location: 'offerings'
        });
        window.localStorage.setItem('whiskey_signup', 'true');
        window.location.assign('/signup');
    };

    return (
        <div className={`${styles.WineWhiskeyContainer}`} ref={ref}>
            {!isMobile && <h2 className={`${runAnimation} ${styles.TopTitle}`}>Our Offerings</h2>}
            <div className={`${styles.WineContainerBg}`}>
                <div className={`${styles.WineWhiskeyContent}`}>
                    <div className={`${styles.ColumnContainer} ${runAnimation}`}>
                        <div className={`${styles.Column}`}>
                            <h3 className={`${styles.ColumnTitle}`}>Wine</h3>
                            <p className={`${styles.ColumnText}`}>
                                Bottle your wealth. This lucrative asset class offers the perfect blend of high
                                performance and personalization, perfect for long-term wealth protection and growth.
                            </p>
                            <ul className={`${styles.ColumnList}`}>
                                <li className={`${styles.ColumnListItem}`}>$1,000 minimum</li>
                                <li className={`${styles.ColumnListItem}`}>5 to 10+ year hold</li>
                                <li className={`${styles.ColumnListItem}`}>Managed and self-directed portfolios</li>
                            </ul>
                            <Button
                                href={baseRoutes.signup.href}
                                variant={'green'}
                                onClick={() => {
                                    wineNext;
                                }}
                            >
                                Invest In Wine
                            </Button>
                            <div className={`${styles.ColumnImage}`}>
                                <Image
                                    src={isMobile ? wineMobile : wine}
                                    width={'701'}
                                    height={'412'}
                                    alt={'Wine Investment Collection'}
                                    loading={'lazy'}
                                />
                            </div>
                        </div>
                        <div className={`${styles.Column}`}>
                            <h3 className={`${styles.ColumnTitle}`}>Whiskey</h3>
                            <p className={`${styles.ColumnText}`}>
                                Are you in good spirits? Diversify with Scotch and American whiskey casks for a simple
                                and straightforward experience. Ideal for first-time or short-term investing.
                            </p>
                            <ul className={`${styles.ColumnList}`}>
                                <li className={`${styles.ColumnListItem}`}>$1,750 minimum</li>
                                <li className={`${styles.ColumnListItem}`}>2 to 10+ year hold</li>
                                <li className={`${styles.ColumnListItem}`}>Managed portfolios</li>
                            </ul>
                            <Button
                                href={baseRoutes.signup.href}
                                variant={'green'}
                                onClick={() => {
                                    whiskeyNext;
                                }}
                            >
                                Invest In Whiskey
                            </Button>
                            <div className={`${styles.ColumnImage} ${styles.whiskey}`}>
                                <Image
                                    src={whiskey}
                                    width={'778'}
                                    height={'368'}
                                    alt={'Whiskey Investment Collection'}
                                    loading={'lazy'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
