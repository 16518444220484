import Image from 'next/image';
import chart from '../../../../../assets/img/home/chart.svg';
import styles from './ReturnsSection.module.scss';
import { useScrollReveal } from '~/hooks/useScrollReveal';
import React from 'react';
import { HomeButton } from '../HomeButton';
import { useWindowSize } from '~/hooks/useWindowSize';

export const ReturnsSection = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { hasRevealed } = useScrollReveal({ ref });
    const runAnimation = hasRevealed ? styles.runAnimation : '';
    const { isMobile } = useWindowSize();

    return (
        <section className={`${styles.ReturnsContainer}`} ref={ref}>
            <div className={styles.ReturnsContent}>
                <div className={`${styles.GraphColumnContainer} ${runAnimation}`}>
                    <div className={`${styles.GraphColumn} ${styles.text}`}>
                        <h2 className={`${styles.TopTitle} ${runAnimation}`}>
                            Stable Returns, Even in Unstable Markets
                        </h2>
                        <p className={`${styles.TopDescription} ${runAnimation}`}>
                            Fine wine and whiskey never go out of style. Both enjoy a low correlation with traditional
                            assets, making them largely immune to the ups and downs of the stock market. That way, you
                            can keep more of your gains when the rest of the market falters.
                        </p>
                        {!isMobile && <HomeButton btnLocation={'returns_chart'} />}
                    </div>
                    <div className={`${styles.GraphColumn} text-center`}>
                        <p className={`${styles.ColumnText}`}>
                            Fine Wine & Whiskey vs. Other Asset Classes: 2015 to 2022
                        </p>
                        <div className={`styles.ColumnImage`}>
                            <Image src={chart} width={702} height={428} alt={'Asset Returns Chart'} loading={'lazy'} />
                        </div>
                        <p className={`${styles.ColumnDisclaimer}`}>
                            The performanced represented in this chart range from January 1, 2015 to December 31, 2022.
                            Fine wine - Liv-ex Fine Wine 1000 Index. Fine whiskey - Rare Whisky Icon 100 Index. S&P 500
                            - S&P 500 Index. Gold - LBMA Gold Price. US Corporate Bonds - S&P 500 Investment Grade
                            Corporate Bond Index. Real Estate - S&P/Case-Shiller U.S. National Home Price Index.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};
