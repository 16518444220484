import investmentJpg from '../../../../../assets/img/home/invest-minutes.png';
import yourPositionJpg from '../../../../../assets/img/home/build.png';
import yourPositionJpgMobile from '../../../../../assets/img/home/build-mo.png';
import minutesJpg from '../../../../../assets/img/home/grow.png';
import minutesJpgMobile from '../../../../../assets/img/home/grow-mo.png';
import Image from 'next/image';
import React from 'react';
import { useScrollReveal } from '~/hooks/useScrollReveal';
import styles from './InvestSection.module.scss';
import { HomeButton } from '../HomeButton';
import { InView } from 'react-intersection-observer';
import { useWindowSize } from '~/hooks/useWindowSize';

export const howItWorks = [
    {
        mobileSrc: investmentJpg,
        src: investmentJpg,
        title: 'Tell us your goals',
        description:
            'Get started with our 1-minute assessment. Our team will only consider wines and whiskeys that fit your specific investing criteria.',
        background: '#efddc7',
        color: '#4f1c28',
        height: 600,
        width: 704,
        position: 'center',
        id: 0
    },
    {
        mobileSrc: yourPositionJpgMobile,
        src: yourPositionJpg,
        gup: true,
        title: 'We build your portfolio',
        description:
            'After you fund your account, our wine experts and cutting-edge algorithm select proven wines and whiskeys that match your unique goals.',
        background: '#c5d5e4',
        color: '#242e35',
        height: 708,
        width: 755,
        position: 'bottom',
        id: 1
    },
    {
        mobileSrc: minutesJpgMobile,
        src: minutesJpg,
        title: 'Watch your portfolio grow',
        description:
            "You own your wine and whiskey 100%. We'll take care of it in the meantime. Buy more, sell, or enjoy them as you wish.",
        background: '#e6c9c9',
        color: '#3c400c',
        height: 1094,
        width: 919,
        position: 'center-adjust',
        id: 2
    }
];

export const InvestSection = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { hasRevealed } = useScrollReveal({ ref });
    const runAnimation = hasRevealed ? styles.runAnimation : '';
    const [current, setCurrent] = React.useState(0);
    const { isMobile } = useWindowSize();

    return (
        <div className={styles.Wrapper} ref={ref}>
            <div className={styles.MobileTitles}>
                <h2 className={styles.TopTitle}>Invest in minutes</h2>
            </div>
            <div className={styles.ImgsWrapper}>
                {howItWorks.map((data) => (
                    <InView
                        as={'div'}
                        key={data.id}
                        id={`${data.id}`}
                        onChange={(inView, entry) => inView && setCurrent(Number(entry.target.getAttribute('id')))}
                    >
                        <div
                            className={`${styles.ImgContainer} ${runAnimation}`}
                            style={{ backgroundColor: data.background }}
                            id={`${data.id}`}
                        >
                            <span className={styles.ImageOverflowColor} style={{ backgroundColor: data.background }} />
                            {data.src && data.mobileSrc && (
                                <Image
                                    src={isMobile ? data.mobileSrc : data.src}
                                    alt={data.title}
                                    width={data.width}
                                    height={isMobile ? 100 : data.height}
                                    className={`${styles.HowImg} ${data.position}`}
                                    loading={'lazy'}
                                />
                            )}
                            <div
                                className={`${styles.MobileDescriptions}`}
                                style={{ backgroundColor: data.background, color: data.color }}
                            >
                                <h2 className={`${styles.OptionTitle}`}>{data.title}</h2>
                                <p className={`${styles.OptionDescription}`}>{data.description}</p>
                            </div>
                        </div>
                    </InView>
                ))}
            </div>
            <div className={`${styles.StickyWrapper}`}>
                <h3 className={`${styles.TopTitle}`}>Invest in minutes</h3>
                {howItWorks.map(({ title, description }, i) => (
                    <div
                        key={title}
                        className={`${styles.Dynamic} ${current === i ? `${styles.active} ${runAnimation}` : ''}`}
                    >
                        <h3 className={`${styles.OptionTitle}`}>{title}</h3>
                        {current === i && <p className={`${styles.OptionDescription}`}>{description}</p>}
                    </div>
                ))}
                <HomeButton btnLocation={'process_steps'} />
            </div>
        </div>
    );
};
