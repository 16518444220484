import { useTracking } from '~/hooks/tracking/useTracking';
import { baseRoutes } from '~/routes/base';
import { AnalyticsEventNames } from '~/tracking';
import { Button } from '~/vinovest-design-system/components';

export interface HomeButtonProps {
    btnLocation: string;
}

export const HomeButton = ({ btnLocation }: HomeButtonProps) => {
    const { captureEvent } = useTracking();
    const handleClick = () => {
        captureEvent(AnalyticsEventNames.SelectGetStarted, { location: btnLocation });
    };

    return (
        <Button
            href={baseRoutes.signup.href}
            variant={'green'}
            onClick={handleClick}
            btnLocation={btnLocation}
            classnames={'h-[76px] flex justify-center items-center w-[174px] sizedButton'}
        >
            GET STARTED
        </Button>
    );
};
